<template>
    <div class="main-panel">
        <div class="content-wrapper">
                <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>